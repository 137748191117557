import Axios, { CancelTokenSource } from 'axios';
import { Field, Form, Formik, FormikProps } from 'formik';
import { isNil, omitBy } from 'lodash';
import * as React from 'react';
import { Modal, OverlayTrigger, Popover, Tooltip } from 'react-bootstrap';
import DocumentTitle from 'react-document-title';
import { Link, useHistory, useLocation, useRouteMatch } from 'react-router-dom';
import { AccountWebUrlType } from '../../../shared';
import { TrademarkCase } from '../../../shared/TrademarkCase';
import User from '../../../shared/User';
import { getAffinityPageTitle } from '../../../utils';
import { FullContent } from '../../ContentFrame';
import { AccountCell, FilterBar, GatewayImage, GatewayModal, LoadingSpinner, ModalType, PaginationFooter, UrlTypeTooltip } from '../../shared';
import { getCaseCounts, getCaseList } from '../Uses/api';
import { CaseFilters } from './CaseFilters';

export const CasesIndexPage = () => {
  const routeMatch = useRouteMatch<{vendorId: string, clientId: string}>();
  const location = useLocation();
  const history = useHistory();
  const [cases, setCases] = React.useState<TrademarkCase[]>([]);
  const [counts, setCounts] = React.useState({ open: 0, closed: 0, all: 0 });
  const [platforms, setPlatforms] = React.useState<AccountWebUrlType[]>([]);
  const [totalPages, setTotalPages] = React.useState(1);
  const [adminUsers, setAdminUsers] = React.useState<User[]>([]);
  const [loading, setLoading] = React.useState(false);
  const [filters, setFilters] = React.useState(new CaseFilters(location.search));
  const [advancedSearch, setAdvancedSearch] = React.useState(false);
  const [cancelToken, setCancelToken] = React.useState<CancelTokenSource | null>(null);
  const [firstLoad, setFirstLoad] = React.useState(true);
  const [routePrefix, setRoutePrefix] = React.useState('');

  React.useEffect(() => {
    const updatedFilters = new CaseFilters(location.search);
    if (firstLoad) {
      if (routeMatch && routeMatch.path.endsWith('/enforce/cases/closed') && updatedFilters.caseStatus === '') {
        updatedFilters.caseStatus = 'closed';
      }
      setFirstLoad(false);
      if (routeMatch && routeMatch.params['vendorId']) {
        setRoutePrefix(`/vendors/${routeMatch.params['vendorId']}`);
      }
      if (routeMatch && routeMatch.params['clientId']) {
        setRoutePrefix(`/clients/${routeMatch.params['clientId']}`);
      }
    }
    setFilters(updatedFilters);
    getItems(updatedFilters);

  },              [location.search]);
  React.useEffect(() => {
    getAdminUsers();
    getUrlTypes();
  },              []);

  const getItems = async (filters: CaseFilters) => {
    if (cancelToken) {
      cancelToken.cancel('Cancelled due to filters change');
      setCancelToken(null);
    }
    const cancelTokenSource = Axios.CancelToken.source();
    setCancelToken(cancelTokenSource);
    let clientId = null;
    let vendorId = null;
    if (routeMatch !== null) {
      if (routeMatch.params['vendorId']) {
        vendorId = routeMatch.params['vendorId'];
      }
      if (routeMatch.params['clientId']) {
        clientId = routeMatch.params['clientId'];
      }
    }
    setLoading(true);
    const f = {
      clientId,
      vendorId,
      keyword: filters.search ? filters.search : null,
      ownerId: filters.userId,
      market: filters.market ? filters.market : null,
      sort: filters.sortBy ? filters.sortBy : null,
      page: filters.page,
      isOpen: filters.caseStatus && filters.caseStatus !== 'all' ? (filters.caseStatus === 'closed' ? 0 : 1) : null,
      startDate: filters.startDate ? filters.startDate : null,
      endDate: filters.endDate ? filters.endDate : null,
      closedStartDate: filters.closedStartDate ? filters.closedStartDate : null,
      closedEndDate: filters.closedEndDate ? filters.closedEndDate : null,
      platform: filters.platform ? filters.platform : null,
    };
    const cleaned = omitBy(f, isNil);

    const c = await getCaseList(cleaned, cancelTokenSource);
    setCases(c.cases);
    if (c.pagination) {
      setTotalPages(c.pagination.total_pages);
    }
    const caseCounts = await getCaseCounts(cleaned);
    const open = caseCounts.data.data.open;
    const closed = caseCounts.data.data.closed;
    if (routeMatch && routeMatch.params['vendorId'] && routeMatch.path.endsWith('/enforce/cases')) {
      if (open > 0 && c.cases.length > 0) {
        return history.push(`${routePrefix}/enforce/cases/${c.cases[0].id}`);
      }
      return history.push(`${routePrefix}/enforce/cases/closed`);
    }

    setCounts({ open, closed, all: open + closed  });
    setLoading(false);
  };

  const getAdminUsers = async () => {
    const u = await Axios.get('/api/users?account_id=1');
    const users = u.data.data.map((u: any) => User.fromApi(u));
    setAdminUsers(users);
  };

  const getUrlTypes = async () => {
    const t = await Axios.get('/api/account-web-url-types');
    setPlatforms(t.data.data.map((type: any) => AccountWebUrlType.fromApi(type)));
  };

  const togglePin = async (id: number) => {
    const currentCases = [... cases];
    const i = currentCases.findIndex(trademarkCase => id === trademarkCase.id);
    if (i !== -1) {
      const selectedCase = currentCases[i];
      currentCases[i].isPinned = !currentCases[i].isPinned;
      setCases(currentCases);
      if (selectedCase.isPinned) {
        await Axios.post(`/api/trademark-cases/${selectedCase.id}/pin`, {});
      } else {
        await Axios.delete(`/api/trademark-cases/${selectedCase.id}/pin`);
      }
    }

  };

  const applyFilters = (f: any, reload: boolean) => {
    if (f.startDate) {
      filters.startDate = f.startDate;
    }
    if (f.endDate) {
      filters.endDate = f.endDate;
    }
    if (f.search !== undefined) {
      filters.search = f.search;
    }
    if (f.market !== undefined) {
      filters.market = f.market;
    }
    if (f.user !== undefined) {
      filters.userId = f.user;
    }
    if (f.page) {
      filters.page = f.page;
    }
    if (f.sort !== undefined) {
      filters.sortBy = f.sort;
    }
    if (f.status !== undefined) {
      filters.caseStatus = f.status;
    }
    if (f.startDate !== undefined) {
      filters.startDate = f.startDate;
    }
    if (f.endDate !== undefined) {
      filters.endDate = f.endDate;
    }
    if (f.closedStartDate !== undefined) {
      filters.closedStartDate = f.closedStartDate;
    }
    if (f.closedEndDate !== undefined) {
      filters.closedEndDate = f.closedEndDate;
    }
    if (f.platform !== undefined) {
      filters.platform = f.platform;
    }
    if (f.search !== undefined) {
      filters.search = f.search;
    }

    if (reload) {
      if (!f.page) {
        filters.page = 1;
      }
      const baseUrl = location.pathname;
      history.replace(`${baseUrl}?${filters.generateQueryParamString()}`);
    }

    const fi = new CaseFilters();
    fi.setFilters(filters.filters);
    setFilters(fi);

  };

  return (
    <FullContent>
      <DocumentTitle title={getAffinityPageTitle('Cases')} />

      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <h3><strong>Cases</strong></h3>
        <div>
          {
            (routeMatch && !routeMatch.params['vendorId'] && !routeMatch.params['clientId'] && routeMatch.path === '/enforce/cases') ?
              <Link className="btn btn-default" to="/enforce/templates" style={{ marginRight: 5 }}>
                Manage Templates
              </Link> : null
          }
        </div>
      </div>
      <FilterBar
        useSearch={true}
        fieldClassOverrides={{ search: 'col-lg-2 col-sm-2' }}
        search={filters.search}
        usePerPage={false}
        useQuarter={false}
        useDates={false}
        startDate={filters.startDate}
        endDate={filters.endDate}
        updateFilters={applyFilters}
        useAdvancedSearch={true}
        advancedSearchOnClick={() => setAdvancedSearch(true)}
      >
        <div className="col-md-2">
          <select value={filters.caseStatus} onChange={e => applyFilters({ status: e.target.value }, true)} className="form-control input-sm" name="status">
            <option value="all">All Cases ({counts.all})</option>
            <option value="open">Open Cases ({counts.open})</option>
            <option value="closed">Closed Cases ({counts.closed}) </option>
          </select>
        </div>
        <div className="col-md-2">
          <div className="form-group">
            <select value={filters.market} onChange={e => applyFilters({ market: e.target.value }, true)} className="form-control input-sm">
              <option value="">All Markets</option>
              <option value="1">Greek</option>
              <option value="2">College</option>
              <option value="3">Associations</option>

            </select>
          </div>
        </div>
        <div className="col-md-2">
          <div className="form-group">
            <select value={filters.platform} onChange={e => applyFilters({ platform: e.target.value }, true)} className="form-control input-sm">
              <option value="">All Platforms</option>
              {platforms.map(p => <option key={p.id} value={p.id}>{p.name}</option>)}
            </select>
          </div>
        </div>
        <div className="col-md-2">
          <div className="form-group">
            <select value={filters.userId} onChange={e => applyFilters({ user: e.target.value }, true)} className="form-control input-sm">
              <option value="">All Users</option>
              {adminUsers.map(u => <option key={u.id} value={u.id}>{u.fullName}</option>)}
            </select>
          </div>
        </div>
        <div className="col-md-2">
          <div className="form-group">
            <select value={filters.sortBy} onChange={e => applyFilters({ sort: e.target.value }, true)} className="form-control input-sm" name="sort">
              <option value="">Sort Last Action, Descending</option>
              <option value="action-asc">Sort Last Action, Ascending</option>
              <option value="asc">Sort Created, Ascending</option>
              <option value="desc">Sort Created, Descending</option>
            </select>
          </div>
        </div>
      </FilterBar>

      <GatewayModal
        type={ModalType.Primary}
        shown={advancedSearch}
        onClose={() => setAdvancedSearch(false)}
        title="Search"
      >
        <Formik onSubmit={(v) => {
          applyFilters({
            market: v.market,
            user: v.owner,
            sort: v.sort,
            status: v.status,
            startDate: v.startDate,
            closedStartDate: v.closedStartDate,
            endDate: v.endDate,
            closedEndDate: v.endDate,
            platform: v.platform,

          },           true);
          setAdvancedSearch(false);
        }}
          initialValues={{
            status: filters.caseStatus,
            market: filters.market,
            owner: filters.userId,
            sort: filters.sortBy,
            startDate: filters.startDate,
            closedStartDate: filters.closedStartDate,
            endDate: filters.endDate,
            closedEndDate: filters.closedEndDate,
            platform: filters.platform,
          }}
          enableReinitialize={true}
        >
          {(formProps: FormikProps<any>) => (
            <Form>
              <Modal.Body>
                <div className="form-group">
                  <label>Status</label>
                  <Field className="form-control" name="status" component="select">
                    <option value="all">All Cases ({counts.all})</option>
                    <option value="open">Open Cases ({counts.open})</option>
                    <option value="closed">Closed Cases ({counts.closed}) </option>
                  </Field>
                </div>
                <div className="form-group">
                  <label>Market</label>
                  <Field className="form-control" name="market" component="select">
                    <option value="">All Markets</option>
                    <option value="1">Greek</option>
                    <option value="2">College</option>
                    <option value="3">Associations</option>
                  </Field>
                </div>
                <div className="form-group">
                  <label>Owner</label>
                  <Field className="form-control" name="owner" component="select">
                    <option value="">All Users</option>
                    {adminUsers.map(u => <option value={u.id}>{u.fullName}</option>)}
                  </Field>
                </div>
                <div className="form-group">
                  <label>Platform</label>
                  <Field name="platform" component="select" className="form-control">
                    <option value="">All Platforms</option>
                    {platforms.map(p => <option value={p.id}>{p.name}</option>)}
                  </Field>
                </div>
                <div className="form-group">
                  <label>Sort</label>
                  <Field className="form-control" name="sort" component="select">
                    <option value="">Sort Last Action, Descending</option>
                    <option value="action-asc">Sort Last Action, Ascending</option>
                    <option value="asc">Sort Created, Ascending</option>
                    <option value="desc">Sort Created, Descending</option>
                  </Field>
                </div>
                <div className="form-group">
                  <label>Created Dates</label>
                  <div className="row">
                    <div className="col-md-6">
                      <input
                        onChange={e => formProps.setFieldValue('startDate', e.target.value)}
                        value={formProps.values.startDate}
                        name="startDate"
                        id="startDate"
                        type="date"
                        className="form-control"
                      />
                    </div>
                    <div className="col-md-6">
                      <input
                        onChange={e => formProps.setFieldValue('endDate', e.target.value)}
                        value={formProps.values.endDate}
                        name="endDate"
                        id="endDate"
                        type="date"
                        className="form-control"
                      />
                    </div>
                  </div>
                </div>
                <div className="form-group">
                  <label>Closed Dates</label>
                  <div className="row">
                    <div className="col-md-6">
                      <input
                        onChange={e => formProps.setFieldValue('closedStartDate', e.target.value)}
                        value={formProps.values.closedStartDate}
                        name="closedStartDate"
                        id="closedStartDate"
                        type="date"
                        className="form-control"
                      />
                    </div>
                    <div className="col-md-6">
                      <input
                        onChange={e => formProps.setFieldValue('closedEndDate', e.target.value)}
                        value={formProps.values.closedEndDate}
                        name="closedEndDate"
                        id="closedEndDate"
                        type="date"
                        className="form-control"
                      />
                    </div>
                  </div>
                </div>

              </Modal.Body>
              <Modal.Footer>
                <button type="button" onClick={() => setAdvancedSearch(false)} className="btn btn-default pull-left">
                  Cancel
                </button>
                <button className="btn btn-primary pull-right">
                  Search
                </button>
              </Modal.Footer>
            </Form>
          )}
        </Formik>
      </GatewayModal>

      {loading ? <LoadingSpinner /> : <div style={{ marginTop: 15 }} className="panel panel-portal">
        <div className="table-responsive">
          <table className="table table-portal table-hover">
            <thead>
              <tr>

                <th style={{ width: 60 }}>Status</th>
                <th>Account</th>
                <th>Recent Note</th>
                <th>Platforms</th>
                <th>Admin</th>
                <th>Created</th>
                <th>Last Action</th>
              </tr>
            </thead>
            <tbody>
              {cases.map(c => (
                <tr key={c.id}>
                  <td style={{ textAlign: 'center' }} >
                    { c.vendor ? <Link to={`/vendors/${c.vendor.id}/enforce`}>{c.closedAt ? <span className="label label-primary">Closed</span> : <span className="label label-danger">Open</span>}</Link> : null}
                  </td>
                  <td>{c.vendor ?

                    <Link style={{ color: '#333333' }} to={`/vendors/${c.vendor.id}/enforce`}>
                      <AccountCell name={c.vendor.accountName} />
                    </Link>
                    : null}</td>
                  <td style={{ overflow: 'hidden', textOverflow: 'ellipsis' }}>
                    {c.latestNoteThread ? (
                      <OverlayTrigger
                        trigger="hover"
                        placement="top"
                        overlay={(
                          <Popover id={`latest-note-${c.latestNoteThread.id}`} title={c.latestNoteThread.subject}>
                            <div>
                              <p >{c.latestNoteThread.previewMessage}</p>
                              <span className="text-muted">
                                Updated on {c.latestNoteThread.updatedAt.format('MMM DD, YYYY')}
                                </span>
                            </div>

                          </Popover>
                        )}
                      >
                        <span style={{ cursor: 'pointer', whiteSpace: 'nowrap' }}>
                          {c.latestNoteThread.subject}
                        </span>
                      </OverlayTrigger>

                    ) : null}
                  </td>
                  <td>
                    <div style={{ display: 'flex', alignItems: 'center', gap: 10  }}>

                      {c.platforms.map(p => <UrlTypeTooltip key={p.id} type={p} />)}
                    </div>

                  </td>
                  <td style={{ width: 100 }}>
                    {c.owner ?
                      <OverlayTrigger
                        trigger="hover"
                        placement="top"
                        overlay={(
                          <Tooltip id={`owner-name-${c.owner.id}-${c.id}`}>
                            {c.owner.fullName}
                          </Tooltip>
                        )}

                      >
                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                          <GatewayImage image={c.owner.image} size="th" width={30} height={30} borderRadius={30} />
                        </div>
                      </OverlayTrigger>
                      : null}
                  </td>
                  <td>
                    {c.createdAt ?

                      <OverlayTrigger
                        trigger="hover"
                        placement="top"
                        overlay={(
                          <Tooltip id={`created-date-${c.id}`}>
                            <span>{c.createdAt.format('MMM DD, YYYY')}</span>
                          </Tooltip>
                        )}

                      >
                        <span style={{ cursor: 'pointer' }}>{c.createdAt.fromNow()}</span>
                        </OverlayTrigger> : ''}
                  </td>
                  <td>
                    {c.latestNoteThread ?

                      <OverlayTrigger
                        trigger="hover"
                        placement="top"
                        overlay={(
                          <Tooltip id={`latest-thread-${c.id}`}>
                            <span>{c.latestNoteThread.updatedAt.format('MMM DD, YYYY')}</span>
                          </Tooltip>
                        )}

                      >
                        <span style={{ cursor: 'pointer' }}>{c.latestNoteThread.updatedAt.fromNow()}</span>
                      </OverlayTrigger> : ''}
                  </td>
                </tr>
              ))}

            </tbody>
          </table>
        </div>
      </div>}

      {loading ? null : <PaginationFooter
        setPage={p => applyFilters({ page: p }, true)}
        currentPage={filters.page}
        totalPages={totalPages}
        hasNext={filters.page < totalPages}

      />}

    </FullContent>
  );
};
