import * as React from 'react';
import { WebBotUrl } from '../../../shared/WebBotUrl';
import { WebBotListingItem } from './WebBotListingItem';

interface IProps {
  urls: WebBotUrl[];

}

export const EnforcementDashboardRecentlyRemoved = (props: IProps) => {
  const { urls } = props;

  if (urls.length) {
    return (
      <div>
      <h5 className="text-muted">Recently Removed</h5>
        <div className="recently-removed">
          {urls.map(r =>
            <WebBotListingItem key={r.id} listing={r} />,
          )}
        </div>
      </div>
    );

  }

  return <></>;

};
