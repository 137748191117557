import * as moment from 'moment';
import { AccountProfile, AccountProfileAPIResponse } from './AccountProfile';
import { AccountWebUrlType } from './AccountWebUrl';
import { AffinityClient } from './AffinityClient';
import { AffinityImage, ImageApiResponse } from './Image';
import User, { UserApiResponse } from './User';
import { WebBotScanRequest, WebBotScanRequestApiResponse } from './WebBotScanRequest';

export interface WebBotSellerApiResponse {
  id: number;
  platform_identifier: string;
  platform_username: string;
  always_infringing: boolean;
  auto_create_case_use: boolean;
  url_count: number;
  case_id: number;
  created_at: string;
  deleted_at: string;
  closed_at: string;
  updated_at: string;
  platform: AccountWebUrlType;
  seller_url: string;
  linked_account?: AccountProfileAPIResponse;
  scan_request?: WebBotScanRequestApiResponse;
  licensor_alerts: number;
  licensors: any;
  image: ImageApiResponse;
  owner?: UserApiResponse;
  created_by?: UserApiResponse;
  ignore_seller: boolean;
  seller_licensors?: any[];
}
export class WebBotSeller {

  id: number;
  platformIdentifier: string;
  platformUsername: string;
  alwaysInfringing: boolean;
  autoCreateCaseUse: boolean;
  urlCount: number;
  caseId: number;
  createdAt: moment.Moment;
  deletedAt: moment.Moment | null;
  closedAt: moment.Moment | null;
  updatedAt: moment.Moment;
  platform: AccountWebUrlType;
  sellerUrl: string;
  linkedAccount: AccountProfile | null;
  scanRequest: WebBotScanRequest | null;
  licensorAlerts: number;
  licensors: AffinityClient[];
  image: AffinityImage | null;
  owner: User | null;
  createdBy: User | null;
  ignoreSeller: boolean;
  sellerLicensors: any[] = [];

  static fromApi(data: WebBotSellerApiResponse) {
    const s = new WebBotSeller();
    const backupImage = new AffinityImage();
    backupImage.defaultImage = 'https://fa53536668741c2c0fa9-ba78fd97d6ea44824b8e89aaeaef1437.ssl.cf1.rackcdn.com/vendor-icon.png';
    s.id = data.id;
    s.platformIdentifier = data.platform_identifier;
    s.platformUsername = data.platform_username;
    s.alwaysInfringing = data.always_infringing;
    s.autoCreateCaseUse = data.auto_create_case_use;
    s.urlCount = data.url_count;
    s.caseId = data.case_id;
    s.linkedAccount = data.linked_account ? AccountProfile.fromApi(data.linked_account) : null;
    s.scanRequest = data.scan_request ? WebBotScanRequest.fromApi(data.scan_request) : null;
    s.createdAt = moment(data.created_at);
    s.deletedAt = data.deleted_at ? moment(data.deleted_at) : null;
    s.updatedAt = moment(data.updated_at);
    s.closedAt = data.closed_at ? moment(data.closed_at) : null;
    s.platform = data.platform;
    s.sellerUrl = data.seller_url;
    s.licensorAlerts = data.licensor_alerts;
    s.licensors = data.licensors && data.licensors.length ? data.licensors.map((l:any) => {
      return {
        id: l.id,
        confirmedAuthorizedAt: l.pivot && l.pivot.confirmed_authorized_at ? moment(l.pivot.confirmed_authorized_at) : null,
        totalProducts: l.total_products,
        shortName: l.short_name,
      };
    }).sort((next: any, prev: any) => {
      const nCheck = next.confirmedAuthorizedAt === null || (next.dateToReverify && moment().isAfter(next.dateToReverify));
      const pCheck = prev.confirmedAuthorizedAt === null || (prev.dateToReverify && moment().isAfter(prev.dateToReverify));
      if (pCheck === nCheck) {
        return next.shortName.toLowerCase() > prev.shortName.toLowerCase() ? 1 : -1;
      }

      return nCheck ? -1 : 1;

    }) : [];
    s.image = data.image ? AffinityImage.fromApi(data.image) : backupImage;
    s.owner = data.owner ? User.fromApi(data.owner) : null;
    s.createdBy = data.created_by ? User.fromApi(data.created_by) : null;
    s.ignoreSeller = data.ignore_seller;
    if (data.seller_licensors) {
      s.sellerLicensors = data.seller_licensors;
    }

    return s;

  }

  wasAuthorized(licensor: number) {
    const matchingLicensor = this.sellerLicensors.find(l => Number(l.client_account_id) === licensor);
    if (matchingLicensor) {
      return matchingLicensor.confirmed_authorized_at !== null;
    }
    return false;
  }

  get hasAnyAuthorization() {
    const anyAuthorized = this.sellerLicensors.findIndex(l => l.confirmed_authorized_at !== null);
    return anyAuthorized !== -1;
  }

  toggleAuthorization(licensor: number) {
    const matchingLicensor = this.sellerLicensors.findIndex(l => Number(l.client_account_id) === licensor);
    if (matchingLicensor !== -1) {
      const current = this.sellerLicensors[matchingLicensor].confirmed_authorized_at;
      this.sellerLicensors[matchingLicensor].confirmed_authorized_at = current ? null : 'now';
      this.sellerLicensors = [... this.sellerLicensors];
    }
  }

  get linkedAccountAlertColor() {
    if (this.linkedAccount && Number(this.linkedAccount.status.id) !== 5) {
      return 'success';
    }

    return 'danger';

  }

}
