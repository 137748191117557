import { faPlus, faTimes } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Axios from 'axios';
import * as React from 'react';
import { Modal } from 'react-bootstrap';
import { useFieldArray, useForm } from 'react-hook-form';
import { useHistory, useParams, useRouteMatch } from 'react-router-dom';
import { AccountWebUrlType, AffinityClient, Trademark, Vendor } from '../../../shared';
import { FullContent } from '../../ContentFrame';
import { GatewayModal, LicensorTypeahead, LoadingSpinner, ModalType, TrademarkTypeahead, VendorTypeahead } from '../../shared';

interface UrlToScrape {
  url: string;
  vendorId?: string;
  clientId?: string;
  clientIds?: string[];
  trademarkId?: string;
  trademarkIds?: string[];
}

interface FormRow {
  url: string;
  platformId: number;
  vendor: Vendor[];
  client: AffinityClient[];
  trademark: Trademark[];
  accountId: number;
  showTrademarkTypeahead: boolean;
}

interface UrlPasteForm {
  urls: string;
}

interface WebBotUrlForm {
  urls: WebBotUrlField[];
}

interface WebBotUrlField {
  url: string;
  client: AffinityClient | null;
  type: number;

}

export const WebBotUrlManualPage = () => {
  const [processing, setProcessing] = React.useState(false);
  const [webUrlTypes, setWebUrlTypes] = React.useState<AccountWebUrlType[]>([]);
  const [changeUrlLicensor , setChangeUrlLicensor] = React.useState<number | null>(null);
  const params = useParams();
  const urlPasteForm = useForm<UrlPasteForm>({
    defaultValues: {
      urls: '',
    },
  });
  const urlSubmissionForm = useForm<WebBotUrlForm>({
    defaultValues: {
      urls: [],
    },

  });
  const urlFieldArray = useFieldArray({
    name: 'urls',
    control: urlSubmissionForm.control,
    rules: {
      validate: {
        hasClient: (u: WebBotUrlField[]) => u.reduce((p, n) =>  {
          if (!p) return p;
          if (!n.client) return false;
          return true;
        }
          ,                                          true),
      },

    },
  });

  const changeLicensorForm = useForm({
    defaultValues: { licensor: [] },
  });

  React.useEffect(() => {
    getWebUrlTypes();

  },              []);

  const getWebUrlTypes = async () => {
    const res = await fetch('/api/account-web-url-types');
    const d =  await res.json();
    setWebUrlTypes(d.data.map((u: any) => AccountWebUrlType.fromApi(u)));
  };

  const parseUrls =  async (values: UrlPasteForm) => {
    const distinct = new Set(values.urls.split('\n'));
    const urls = { urls: Array.from(distinct) };
    if (urls.urls.length && !processing)  {
      setProcessing(true);
      const res = await fetch('/api/enforcement/url-parse', {
        method : 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(urls),
      });
      const d = await res.json();
      const checkedUrls: WebBotUrlField[] = d.data.map((u : any) => {

        return { url: u.url, client: u.client ? new AffinityClient(u.client) : null, type: u.type.id };
      });
      urlFieldArray.append(checkedUrls);
      setProcessing(false);
    }
  };

  const submitUrls = async (values: WebBotUrlForm) => {
    const urls = values.urls.map((u) => {
      let id = null;
      if (u.client) {
        id = u.client.id;
      }

      return { url: u.url, type: u.type, licensor: id };

    });
    const data = {
      urls,
      vendor: params['vendorId'],
    };
    const res = await fetch('/api/enforcement/urls', {
      method : 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    });

  };

  const setLicensor = (values: {licensor: AffinityClient[]}) => {
    if (changeUrlLicensor !== null) {
      urlSubmissionForm.setValue(`urls.${changeUrlLicensor}.client`, values.licensor[0]);
      setChangeUrlLicensor(null);
    }

  };

  const typeOptions = webUrlTypes.map(t => (<option value={t.id}>{t.name}</option>));

  let panelBody;
  if (processing) {
    panelBody = <LoadingSpinner />;
  } else if (urlFieldArray.fields.length) {
    panelBody = (
      <form onSubmit={urlSubmissionForm.handleSubmit(submitUrls)}>
        <div>
          <p className="text-muted text-center">All clients will use their default trademark.</p>
          {urlSubmissionForm.formState.errors.urls && urlSubmissionForm.formState.errors.urls.root ? <div className="alert alert-danger">All urls must have a client.</div> : null
          }
          {urlFieldArray.fields.map((u, i) => (
            <div key={u.id} className="web-bot-url-manual-row">
              <div className="manual-row-url">
                <a href={urlSubmissionForm.getValues(`urls.${i}.url`)} target="_blank">{urlSubmissionForm.getValues(`urls.${i}.url`)}</a>
              </div>

              <div className="manual-row-type">
                <select {... urlSubmissionForm.register(`urls.${i}.type`)} className="form-control">
                  {typeOptions}
                </select>
              </div>

              <div onClick={() => setChangeUrlLicensor(i)} className="manual-row-client">
                {urlSubmissionForm.getValues(`urls.${i}.client`) ?
                  <>
                    <img src={urlSubmissionForm.getValues(`urls.${i}.client`).affinityImage.getSize('th')} className="img-responsive" />
                    <strong>{urlSubmissionForm.getValues(`urls.${i}.client`).shortName}</strong>
                  </>
                  :
                  <strong>
                    Licensor not found - Click to set licensor
                  </strong>
                }
              </div>
            </div>
          ))}
        </div>
        <button type="submit" className="btn btn-primary  pull-right">Submit</button>
      </form>
    );

  } else {

    panelBody = (
      <form onSubmit={urlPasteForm.handleSubmit(parseUrls)}>
        <div className="form-group">
          <textarea rows={20} {...urlPasteForm.register('urls')} className="form-control">

          </textarea>
        </div>
        <button type="submit" className="btn btn-primary  pull-right">Next</button>
      </form>
    );
  }

  return (
    <FullContent>
      <h3> <strong>Add Enforcement Content</strong> </h3>
      <div className="panel panel-portal">
        <div className="panel-body">
          {panelBody}
        </div>
      </div>
      <GatewayModal
        type={ModalType.Primary}
        shown={changeUrlLicensor !== null}
        onClose={() => setChangeUrlLicensor(null)}
        title={'Change Licensor'}
      >
        <form onSubmit={changeLicensorForm.handleSubmit(setLicensor)}>
          <Modal.Body>
            <div>
              <div className="form-group">
                <label>Licensor</label>
                <LicensorTypeahead
                  selected={changeLicensorForm.watch('licensor')}
                  onChange={l => changeLicensorForm.setValue('licensor', l)}
                  multiple={false}
                />
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <button type="button" onClick={() => setChangeUrlLicensor(null)} className="btn btn-default">
              Close
            </button>
            <button type="submit" className="btn btn-primary pull-right">Change Licensor</button>
          </Modal.Footer>
        </form>
      </GatewayModal>
    </FullContent>
  );

};

export const UsesNewPage = () => {
  const [submitting, setSubmitting] = React.useState(false);
  const [processing, setProcessing] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState('');
  const [step, setStep] = React.useState(1);
  const [webUrlTypes, setWebUrlTypes] = React.useState<AccountWebUrlType[]>([]);
  const [loadingTypes, setLoadingTypes] = React.useState(true);
  const [duplicateUrls, setDuplicateUrls] = React.useState<string[]>([]);
  const [allLicensors, setAllLicensors] = React.useState<AffinityClient[]>([]);
  const [trademarkMap, setTrademarkMap] = React.useState<{ [accountId: number]: Trademark[] }>({});
  const history = useHistory();
  const match = useRouteMatch<{ vendorId?: string; clientId?: string; caseId?: string }>();

  const defaultValues = {
    urls: [
      {
        url: '',
        platformId: 1,
        vendor: [],
        client: [],
        trademark: [],
        accountId: 0,
        showTrademarkTypeahead: false,
      },
    ],
  };

  const { register, handleSubmit, getValues, setValue, control, formState: { errors } } = useForm({
    defaultValues,
  });

  const { fields, append, remove, replace } = useFieldArray({
    control,
    name: 'urls',
  });

  React.useEffect(() => {
    fetchInitialData();
  },              []);

  async function fetchInitialData() {
    try {
      const urlTypeResp = await Axios.get('/api/account-web-url-types');
      const types = urlTypeResp.data.data.map((typeData: any) => AccountWebUrlType.fromApi(typeData));
      setWebUrlTypes(types);
      setLoadingTypes(false);
    } catch (e) {
      setLoadingTypes(false);
    }
    try {
      const l = await Axios.get('/api/licensors?includes=profile');
      const licensorData = l.data.data.map((u: any) => new AffinityClient(u));
      setAllLicensors(licensorData);
    } catch (e) {}
  }

  async function submitUrlsToScrape(urlsToScrape: UrlToScrape[]) {
    const urls = urlsToScrape.map(item => ({
      url: item.url,
      vendor_id: item.vendorId,
      client_id: item.clientId,
      client_ids: item.clientIds,
      trademark_id: item.trademarkId,
      trademark_ids: item.trademarkIds,
    }));
    try {
      const response = await Axios.post('/api/trademark-use-urls', { urls });
      return response.data.message;
    } catch (error) {
      if (error.response) {
        return error.response.data.message;
      }
      return `Error submitting URLs: ${error}`;
    }
  }

  function validateUrl(value: string) {
    if (!value || !value.trim()) return 'URL is required';
    try {
      new URL(value);
      return true;
    } catch (err) {
      return 'Must be a valid URL.';
    }
  }

  function parseHostname(urlString: string) {
    try {
      const parsed = new URL(urlString);
      return parsed.hostname.toLowerCase();
    } catch (e) {
      return '';
    }
  }

  function detectPlatform(urlString: string) {
    const hostname = parseHostname(urlString);
    const cleanHostname = hostname.replace(/^www\./, '');
    let matchType: AccountWebUrlType | null = null;
    let bestMatchLength = 0;
    const directMatch = webUrlTypes.find(type => cleanHostname === type.name.toLowerCase());
    if (directMatch) return directMatch.id;
    for (let i = 0; i < webUrlTypes.length; i++) {
      const type = webUrlTypes[i];
      const typeName = type.name.toLowerCase();
      if (cleanHostname.indexOf(typeName) !== -1) {
        if (typeName.length > bestMatchLength) {
          bestMatchLength = typeName.length;
          matchType = type;
        }
      }
    }
    return matchType ? matchType.id : 1;
  }

  const onClientChange = (clients: AffinityClient[], index: number) => {
    setValue(`urls.${index}.client`, clients);
    setValue(`urls.${index}.trademark`, []);
    if (clients.length > 0) {
      setValue(`urls.${index}.accountId`, clients[0].id);
      setValue(`urls.${index}.showTrademarkTypeahead`, true);
      const acct = clients[0].id;
      if (!trademarkMap[acct]) {
        loadTrademarksForAccountId(acct).then(() => {});
      }
    } else {
      setValue(`urls.${index}.accountId`, 0);
      setValue(`urls.${index}.showTrademarkTypeahead`, false);
    }
  };

  async function loadTrademarksForAccountId(accountId: number) {
    if (trademarkMap[accountId]) {
      return;
    }
    try {
      const t = await Axios.get('/api/trademarks', { params: { account_id: accountId } });
      const trademarks = t.data.data.map((u: any) => Trademark.fromApi(u));
      const newMap = { ...trademarkMap };
      newMap[accountId] = trademarks;
      setTrademarkMap(newMap);
    } catch (e) {}
  }

  async function handleTextareaNext() {
    setProcessing(true);
    const val = getValues('urls.0.url');
    const arr = val.split('\n').map(l => l.trim()).filter(l => l);
    if (arr.length === 0) {
      setProcessing(false);
      return;
    }
    try {
      const response = await Axios.get('/api/account-web-urls', { params: { url: arr } });
      const existingUrls: string[] = [];
      if (response.data && response.data.data) {
        for (let i = 0; i < response.data.data.length; i++) {
          existingUrls.push(response.data.data[i].url);
        }
      }
      const duplicates = arr.filter((url: string) => existingUrls.indexOf(url) !== -1);
      setDuplicateUrls(duplicates);
      autoDetectUrls(arr);
      setStep(2);
    } catch (error) {
      autoDetectUrls(arr);
      setStep(2);
    } finally {
      setProcessing(false);
    }
  }

  function autoDetectUrls(lines: string[]) {
    const newRows: FormRow[] = [];
    for (let i = 0; i < lines.length; i++) {
      const raw = lines[i];
      newRows.push({
        url: raw,
        platformId: detectPlatform(raw),
        vendor: [],
        client: [],
        trademark: [],
        accountId: 0,
        showTrademarkTypeahead: false,
      });
    }
    replace(newRows);
  }

  async function onSubmit(data: any) {
    setSubmitting(true);
    const final: UrlToScrape[] = [];
    for (let i = 0; i < data.urls.length; i++) {
      const row = data.urls[i];
      let vendorId: string | undefined;
      let clientId: string | undefined;
      const clientIds: string[] = [];
      const trademarkIds: string[] = [];
      if (row.vendor && row.vendor.length) {
        vendorId = row.vendor[0].id;
      } else if (match.params.vendorId) {
        vendorId = match.params.vendorId;
      }
      if (row.client && row.client.length) {
        clientId = row.client[0].id;
        clientIds.push(clientId);
      } else if (match.params.clientId) {
        clientId = match.params.clientId;
        clientIds.push(clientId);
      }
      if (row.trademark && row.trademark.length) {
        trademarkIds.push(row.trademark[0].id);
      }
      final.push({
        vendorId,
        clientId,
        clientIds,
        trademarkIds,
        url: row.url,
      });
    }
    try {
      const result = await submitUrlsToScrape(final);
      if (result !== 'Duplicate URL') {
        let route = '/enforce';
        if (match && history.location.pathname.indexOf('vendor') !== -1) {
          if (match.params.caseId) {
            route = `/vendors/${match.params.vendorId}/enforce/cases/${match.params.caseId}`;
          } else {
            route = `/vendors/${match.params.vendorId}/enforce`;
          }
        }
        history.push(route);
      } else {
        setErrorMessage('One or more of the submitted URLS is a duplicate');
        setSubmitting(false);
      }
    } catch (error) {
      setErrorMessage(`Error submitting URLs: ${error}`);
      setSubmitting(false);
    }
  }

  const handleBackToStep1 = () => {
    const lines: string[] = [];
    for (let i = 0; i < fields.length; i++) {
      const rowUrl = getValues(`urls.${fields[i].id}.url`);
      if (rowUrl) {
        lines.push(rowUrl);
      }
    }
    const joined = lines.join('\n');
    replace([{ ...defaultValues.urls[0], url: joined }]);
    setStep(1);
  };

  if (submitting || loadingTypes) {
    return (
      <FullContent>
        <LoadingSpinner />
      </FullContent>
    );
  }

  return (
    <FullContent>
      <h3> <strong>Add Trademark Uses</strong> </h3>
      <div className="panel panel-portal">
        {step === 1 && (
          <div className="panel-body">
            <p className="text-muted text-center">
              Paste one or more URLs (one per line).
            </p>
            <textarea
              {...register('urls.0.url', { validate: validateUrl })}
              rows={6}
              className="form-control"
              placeholder="Paste your URLs here, one per line"
            />
            {errors.urls && errors.urls[0] && errors.urls[0].url && (
              <p className="text-danger">{errors.urls[0].url.message}</p>
            )}
            <div className="panel-footer" style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center', paddingLeft: 15, paddingRight: 15, paddingTop: 15 }}>
              <button
                type="button"
                className="btn btn-primary"
                onClick={handleTextareaNext}
                disabled={processing}
              >
                {processing ? 'Processing...' : 'Process URLs'}
              </button>
            </div>
          </div>
        )}
        {step === 2 && (
          <form onSubmit={handleSubmit(onSubmit)}>
            {errorMessage && (
              <div className="alert alert-danger">{errorMessage}</div>
            )}
            {duplicateUrls.length > 0 && (
              <div className="alert alert-warning">
                The following URLs are duplicates and already exist:
                <ul>
                  {duplicateUrls.map(url => <li key={url}>{url}</li>)}
                </ul>
              </div>
            )}
            {fields.map((field, index) => {
              const currentAcct = getValues(`urls.${index}.accountId`);
              let preloadedTrademarks: Trademark[] = [];
              if (trademarkMap[currentAcct]) {
                preloadedTrademarks = trademarkMap[currentAcct];
              }
              return (
                <div className="panel-body" key={field.id}>
                  {index > 0 && <hr />}
                  <div style={{ paddingTop: 10 }} className="row">
                    <div className="col-md-2">
                      <input
                        className="form-control"
                        {...register(`urls.${index}.url`, { validate: validateUrl })}
                        placeholder="Content URL"
                      />
                      {errors.urls && errors.urls[index] && errors.urls[index].url && (
                        <p className="text-danger">{errors.urls[index].url.message}</p>
                      )}
                    </div>
                    <div className="col-md-2">
                      <select
                        className="form-control"
                        {...register(`urls.${index}.platformId`)}
                      >
                        {webUrlTypes.map(p => (
                          <option key={p.id} value={p.id}>
                            {p.name}
                          </option>
                        ))}
                      </select>
                    </div>
                    <div className="col-md-3">
                      {!match.params.clientId && (
                        <LicensorTypeahead
                          preloadedLicensors={allLicensors}
                          selected={getValues(`urls.${index}.client`)}
                          onChange={(v: AffinityClient[]) => onClientChange(v, index)}
                          multiple={false}
                        />
                      )}
                    </div>
                    <div className="col-md-4">
                      <TrademarkTypeahead
                        preloadedTrademarks={preloadedTrademarks}
                        selected={getValues(`urls.${index}.trademark`)}
                        onChange={(v: Trademark[]) => setValue(`urls.${index}.trademark`, v)}
                        multiple={false}
                        accountId={getValues(`urls.${index}.accountId`)}
                        delayLoad={true}
                      />
                    </div>
                    <div className="col-md-1" style={{ display: 'flex', alignItems: 'center' }}>
                      {fields.length > 1 && (
                        <FontAwesomeIcon
                          className="text-danger"
                          icon={faTimes}
                          onClick={() => remove(index)}
                          style={{ cursor: 'pointer' }}
                        />
                      )}
                    </div>
                  </div>
                </div>
              );
            })}
            <div className="panel-footer" style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', paddingLeft: 15, paddingRight: 15, paddingTop: 15 }}>
              <button
                type="button"
                className="btn btn-default"
                onClick={handleBackToStep1}
              >
                Back to Step 1
              </button>
              <div>
                <button
                  type="button"
                  onClick={() => {
                    append({ ...defaultValues.urls[0] });
                  }}
                  className="btn btn-primary"
                  style={{ marginRight: 10 }}
                >
                  <FontAwesomeIcon icon={faPlus} /> Add URL
                </button>
                <button type="submit" className="btn btn-primary">
                  Submit
                </button>
              </div>
            </div>
          </form>
        )}
      </div>
    </FullContent>
  );
};
