import * as moment from 'moment';
import { AffinityImage } from '.';
import { AccountWebUrlType } from './AccountWebUrl';
import { ImageSize } from './Image';
import { Trademark } from './Trademark';
import { WebBotSeller } from './WebBotSeller';

export interface WebBotUrlApiResponse {
  licensors: any;
  id: number;
  platform_identifier: string;
  full_url: string;
  name: string;
  description: string;
  price:string;
  possible_licensed: boolean | null;
  image_url: string;
  status: { id: number, name: string };
  created_at: string;
  deleted_at: string;
  updated_at: string;
  tags: string[];
  seller: any;
  type: any;
  image?: any;

}
export enum WebBotUrlStatuses {
  NeedsReview = 1,
  Removed = 2,
  Ignored = 3 ,
  NonInfringing = 4,
  Authorized = 5,
  Processing = 6,
  NeedsTakedown = 7,
  PendingRemoval = 8,
}

export class WebBotUrl {

  id: number;
  platformIdentifier: string;
  fullUrl: string;
  name: string;
  description: string;
  price: string;
  possibleLicensed: boolean | null;
  imageUrl: string;
  licensors: any;
  status: { id: number, name: string };
  createdAt: moment.Moment;
  updatedAt: moment.Moment;
  deletedAt: moment.Moment | null;
  tags: string[] = [];
  seller: WebBotSeller;
  type: AccountWebUrlType;
  image: AffinityImage;

  static fromApi(data: WebBotUrlApiResponse) {
    const u = new WebBotUrl();
    u.id = data.id;
    u.platformIdentifier = data.platform_identifier;
    u.fullUrl = data.full_url;
    u.name = data.name;
    u.description = data.description;
    u.price = data.price;
    u.possibleLicensed = data.possible_licensed;
    u.imageUrl = data.image_url;
    u.status = data.status;
    u.licensors = data.licensors && data.licensors.length ? data.licensors.map((l:any) => {
      return {
        id: l.id,
        totalProducts: l.total_products,
        shortName: l.short_name,
        trademarks: l.trademark ? [Trademark.fromApi(l.trademark)] : [],
        defaultTrademark: l.default_trademark,
        affinityImage: l.image_id ? AffinityImage.fromApi({ id:0 , previous_file_name:'', urls:l.image }) : new AffinityImage(),
        image: l.image ? l.image : null,
      };
    }) : [];
    u.createdAt = moment(data.created_at);
    u.updatedAt = moment(data.updated_at);
    u.deletedAt = data.deleted_at ? moment(data.deleted_at) : null;
    u.tags = data.tags;
    if (data.seller) {
      u.seller = WebBotSeller.fromApi(data.seller);
    }
    if (data.type) {
      u.type = AccountWebUrlType.fromApi(data.type);
    }
    if (data.image) {
      u.image = AffinityImage.fromApi(data.image);
    }
    return u;

  }

  static fromExisting(url: WebBotUrl) {
    const u = new WebBotUrl();
    u.id = url.id;
    u.platformIdentifier = url.platformIdentifier;
    u.fullUrl = url.fullUrl;
    u.name = url.name;
    u.description = url.description;
    u.price = url.price;
    u.possibleLicensed = url.possibleLicensed;
    u.imageUrl = url.imageUrl;
    u.status = url.status;
    u.licensors = [...url.licensors];
    u.createdAt = url.createdAt;
    u.updatedAt = url.updatedAt;
    u.deletedAt = url.deletedAt;
    u.seller = url.seller;
    u.type = url.type;
    return u;
  }

  removeLicensor(licensor: number, inverse: boolean) {
    const matchingLicensor = this.licensors.findIndex((l: any) => Number(l.id) === licensor);
    if (matchingLicensor !== -1) {
      if (inverse) {
        this.licensors = [this.licensors[matchingLicensor]];
      } else {
        const s = [... this.licensors];
        s.splice(matchingLicensor, 1);
        this.licensors = [... s];
      }
    }
  }

  correctImage(size: ImageSize) {
    if (this.image) {
      return this.image.getSize(size);
    } if (this.imageUrl) {
      return this.imageUrl;
    }
    return ''; // What can we use as a placeholder if an image is missing eg. Manual Use or Failed scrape
  }

  get statusColor() {
    const c = this.status.id;
    switch (c) {
      case 1:
        return 'text-primary';
      case 2:
        return 'text-danger';
      case 6:
        return 'text-primary';
      case 7:
        return 'text-warning';
      case 8:
        return 'text-warning';
      default:
        return 'text-success';
    }

  }

}
