import * as React from 'react';
import { Redirect } from 'react-router';
import { UserContext } from '../../contexts';
import { EnforcementDashboardPage } from './Dashboard';

export const EnforcementIndexRedirect = () => {
  const user = React.useContext(UserContext);

  if (user.type === 'admin') {
    return <Redirect to="/enforce/cases" />;
  }  if (user.type === 'client') {
    return <EnforcementDashboardPage />;
  }
  return <Redirect to="/" />;
};
