import { faFilter } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as React from 'react';
import { Modal } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { Redirect, useHistory, useLocation, useRouteMatch } from 'react-router';
import { AccountWebUrlType, AffinityClient, AffinityMarket, LaravelPaginationDetails } from '../../../shared';
import { WebBotUrl, WebBotUrlApiResponse } from '../../../shared/WebBotUrl';
import { FullContent } from '../../ContentFrame';
import { FilterBar, GatewayModal, LicensorTypeahead, LoadingSpinner, MarketTypeahead, ModalType, PaginationFooter } from '../../shared';
import { WebBotListingItem } from '../Dashboard';
import { WebBotUrlFilters } from '../WebBotUrlFilters';

export const WebBotUrlIndexPage = () => {
  const location = useLocation();
  const history = useHistory();
  const match = useRouteMatch();
  const [urls, setUrls] = React.useState<WebBotUrl[]>([]);
  const [urlTypes, setUrlTypes] = React.useState<AccountWebUrlType[]>([]);
  const [loading, setLoading] = React.useState(false);
  const [filters, setFilters] = React.useState(WebBotUrlFilters.getInitialFilters(location.search));
  const [pagination, setPagination] = React.useState<LaravelPaginationDetails>({ total: 0, total_pages: 0, current_page: 1, count: 0, per_page: 24 });
  const [advancedFilters, setAdvancedFilters] = React.useState(false);
  const advancedFiltersForm = useForm<any>({
    defaultValues: {
      licensor: [],
      market: [],
    },
  });

  if (!match) {
    return <Redirect to="/" />;
  }

  React.useEffect(() => {
    getUrlTypes();

  },              []);

  React.useEffect(() => {
    getUrls();

  },              [location.search]);

  React.useEffect(() => {
    if (filters.licensorId) {
      advancedFiltersForm.setValue('licensor', [new AffinityClient({ id: filters.licensorId, short_name: 'Loading' })]);
    }

    if (filters.market) {
      advancedFiltersForm.setValue('market', [AffinityMarket.marketFromId(filters.market)]);
    }

  },              [advancedFilters]);

  const getUrls =  async () => {
    setLoading(true);
    const params = filters.generateApiParamString(match.params);
    const response = await fetch(`/api/enforcement/urls?${params}`);
    const data = await response.json();

    setUrls(data.data.map((r: WebBotUrlApiResponse) => WebBotUrl.fromApi(r)));
    setPagination(data.meta.pagination);
    setLoading(false);
  };

  const getUrlTypes = async () => {
    const r = await fetch('/api/web-url-types');
    const data = await r.json();
    setUrlTypes(data.data.map((t: any) => AccountWebUrlType.fromApi(t)));
  };

  const filter = (value: any, submit: boolean) => {
    console.log(value, submit);
    const f = new WebBotUrlFilters();
    f.setFilters(filters.allFilters);
    const replacentValues = { ... value };
    if (!value.page) {
      replacentValues.page = 1;
    }
    f.setFilters(replacentValues);
    setFilters(f);
    if (submit) {
      const qs = f.generateQueryParamString();
      history.push(`${location.pathname}?${qs}`);
    }
  };

  return (
    <FullContent>
      <h3><strong>Content Review</strong></h3>
      <p style={{ marginBottom: 20 }} className="text-muted">Monitor and manage content found on ecommerce platforms.</p>
      <FilterBar
        useSearch={true}
        search={filters.search}
        updateFilters={filter}
        usePerPage={false}
        useQuarter={false}
      >
        <div className="col-lg-2 col-sm-2 col-xs-12">
          <select
            onChange={v => filter({ platform: v.target.value }, true)}
            value={filters.platform}
            className="form-control input-sm"
          >
            <option value={0}>All Platforms</option>
            {urlTypes.map(t => <option value={t.id}>{t.name}</option>)}
          </select>
        </div>
        <div className="col-lg-2 col-sm-2 col-xs-12">
          <select
            onChange={v => filter({ status: v.target.value }, true)}
            value={filters.status}
            className="form-control input-sm"
          >
            <option value={0}>All Listings</option>
            <option value={1}>Needs Review</option>
            <option value={2}>Removed</option>
            <option value={5}>Authorized</option>
            <option value={6}>Processing</option>
            <option value={7}>Needs Takedown</option>
            <option value={8}>Pending Removal</option>
          </select>
        </div>

        <div className="col-lg-2 col-sm-2 col-xs-12">
          <select
            onChange={v => filter({ sortBy: v.target.value }, true)}
            value={filters.sortBy}
            className="form-control input-sm"
          >
            <option value={''}>Default Sort</option>
            <option value={'created-desc'}>Sort Created, Descending</option>
            <option value={'created-asc'}>Sort Created, Ascending</option>
          </select>
        </div>
        {match.params['licensorId'] ? null : <button onClick={() => setAdvancedFilters(true)} className="button btn btn-default btn-sm"><FontAwesomeIcon className={filters.shouldHightFilterButton ? 'text-primary' : undefined} icon={faFilter} /> Filter</button>}
      </FilterBar>
      {loading ? <LoadingSpinner /> : <div>
        <div className="web-bot-url-index-grid">
          {urls.map(u => (<WebBotListingItem listing={u} />))}
        </div>
        <PaginationFooter hasNext={filters.page < pagination.total_pages} totalPages={pagination.total_pages} totalResults={pagination.total} currentPage={filters.page} setPage={page => filter({ page }, true)} />
      </div>}
      <GatewayModal shown={advancedFilters} title="Advanced Filters" type={ModalType.Primary} onClose={() => setAdvancedFilters(false)} >
        <form onSubmit={advancedFiltersForm.handleSubmit((v) => {
          filter({
            licensorId: v.licensor.length ? v.licensor[0].id : null,
            market: v.market.length ? v.market[0].id : null,
          },     true);
          setAdvancedFilters(false);

        })}>
          <Modal.Body>
            <div className="form-group">
              <label>Licensor</label>
              <LicensorTypeahead
                onChange={l => advancedFiltersForm.setValue('licensor', l)}
                multiple={false}
                selected={advancedFiltersForm.watch('licensor')}
              />
            </div>
            <div className="form-group">
              <label>Market</label>
              <MarketTypeahead
                selected={advancedFiltersForm.watch('market')}
                multiple={false}
                onChange={m => advancedFiltersForm.setValue('market', m)}
              />
            </div>
          </Modal.Body>
          <Modal.Footer>
            <button type="button" onClick={() => setAdvancedFilters(false)} className="btn btn-default pull-left">Close</button>
            <button type="submit" className="btn btn-default pull-right">Submit</button>
          </Modal.Footer>
        </form>
      </GatewayModal>
    </FullContent>
  );
};
