import * as React from 'react';
import { Redirect, useRouteMatch } from 'react-router';
import { AccountWebUrlType } from '../../../shared';
import { WebBotUrl } from '../../../shared/WebBotUrl';
import { FullContent } from '../../ContentFrame';
import { LoadingSpinner } from '../../shared';
import { EnforcementDashboardCounts, EnforcementDashboardPlatformSummary } from './enforcement-dashboard-shared';
import { EnforcementDashboardCountSummary } from './EnforcementDashboardCountSummary';
import { EnforcementDashboardRecentlyRemoved } from './EnforcementDashboardRecentlyRemoved';
import { EnforcementPlatformSellerDetails } from './EnforcementPlatformSellerDetails';

export const EnforcementDashboardPage = () => {
  const match = useRouteMatch();
  const [loading, setLoading] = React.useState(false);
  const [platforms, setPlatforms] = React.useState<EnforcementDashboardPlatformSummary[]>([]);
  const [counts, setCounts] = React.useState<EnforcementDashboardCounts>({
    sellers: 0,
    listings: 0,
    removed: 0,
    pending: 0,
    review: 0,
  });
  const [recentlyRemoved, setRecentlyRemoved] = React.useState<WebBotUrl[]>([]);

  React.useEffect(() => {
    getData();

  },              []);

  if (!match) {
    return <Redirect to="/" />;
  }

  const getData = async () => {
    setLoading(true);
    await Promise.all([getUrls(), getSummary()]);
    setLoading(false);

  };

  const getUrls =  async () => {
    const response = await fetch(`/api/enforcement/urls?licensor=${match.params['licensorId']}&status=2&limit=3`);

    const data = await response.json();

    setRecentlyRemoved(data.data.map((r: any) => WebBotUrl.fromApi(r)));
  };

  const getSummary =  async () => {
    const response = await fetch(`/api/enforcement/dashboard?licensor=${match.params['licensorId']}`);

    const data = await response.json();

    const p = data.data.platforms.map((p: any) => {
      const d = p;
      d.platform = AccountWebUrlType.fromApi(d.platform);
      d.recent = d.recent.map((w: any) => WebBotUrl.fromApi(w));
      return d;
    });

    setPlatforms(p);
    setCounts(data.data.counts);
  };

  if (loading) {
    return (
      <FullContent>
        <LoadingSpinner />
      </FullContent>
    );
  }

  return (
    <FullContent>
      <div>
        <h3>Enforce</h3>
        <p className="text-muted small">Monitor and manage content found on ecommerce platforms.</p>
        <EnforcementDashboardCountSummary counts={counts} />

        <h5 className="text-muted">Ecommerce Platforms</h5>

        <div className="enforcement-dashboard-platforms">
          {platforms.map(p => (<EnforcementPlatformSellerDetails platform={p} />))}
        </div>
        <EnforcementDashboardRecentlyRemoved urls={recentlyRemoved} />
      </div>
    </FullContent>
  );
};
