import * as React from 'react';
import { Link } from 'react-router-dom';
import { EnforcementDashboardCounts } from './enforcement-dashboard-shared';

interface IProps {
  counts: EnforcementDashboardCounts;
}

export const EnforcementDashboardCountSummary = (props: IProps) => {
  const { counts } = props;

  return (
    <div className="enforcement-dashboard-detail-panels" >
      <div className="panel panel-portal">
        <div className="panel-body">
          <h6 className="text-primary">Sellers</h6>
          <h3>{counts.sellers}</h3>
        </div>
      </div>
      <div className="panel panel-portal">
        <div className="panel-body">
          <h6 className="text-primary">Listings</h6>
          <h3><Link style={{ color: '#333' }} to={'enforce/content'} >{counts.listings}</Link></h3>
        </div>
      </div>
      <div className="panel panel-portal">
        <div className="panel-body">
          <h6 className="text-primary">Needs review</h6>
          <h3>{counts.review}</h3>
        </div>
      </div>
      <div className="panel panel-portal">
        <div className="panel-body">
          <h6 className="text-primary">Pending removal</h6>
          <h3>{counts.pending}</h3>
        </div>
      </div>
      <div className="panel panel-portal">
        <div className="panel-body">
          <h6 className="text-primary">Removed</h6>
          <h3>{counts.removed}</h3>
        </div>
      </div>
    </div>
  );

};
