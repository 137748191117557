import * as React from 'react';
import { Modal } from 'react-bootstrap';
import { useFieldArray, useForm } from 'react-hook-form';
import { useHistory, useParams } from 'react-router-dom';
import { AccountWebUrlType, AffinityClient } from '../../../shared';
import { FullContent } from '../../ContentFrame';
import { GatewayModal, LicensorTypeahead, LoadingSpinner, ModalType } from '../../shared';

interface UrlPasteForm {
  urls: string;
}

interface WebBotUrlForm {
  urls: WebBotUrlField[];
}

interface WebBotUrlField {
  url: string;
  client: AffinityClient | null;
  type: number;

}

export const ManualWebBotUrlForm = () => {
  const [processing, setProcessing] = React.useState(false);
  const [webUrlTypes, setWebUrlTypes] = React.useState<AccountWebUrlType[]>([]);
  const [changeUrlLicensor , setChangeUrlLicensor] = React.useState<number | null>(null);
  const history = useHistory();
  const params = useParams();
  const urlPasteForm = useForm<UrlPasteForm>({
    defaultValues: {
      urls: '',
    },
  });
  const urlSubmissionForm = useForm<WebBotUrlForm>({
    defaultValues: {
      urls: [],
    },

  });
  const urlFieldArray = useFieldArray({
    name: 'urls',
    control: urlSubmissionForm.control,
    rules: {
      validate: {
        hasClient: (u: WebBotUrlField[]) => u.reduce((p, n) =>  {
          if (!p) return p;
          if (!n.client) return false;
          return true;
        }
          ,                                          true),
      },

    },
  });

  const changeLicensorForm = useForm({
    defaultValues: { licensor: [] },
  });

  React.useEffect(() => {
    getWebUrlTypes();

  },              []);

  const getWebUrlTypes = async () => {
    const res = await fetch('/api/account-web-url-types');
    const d =  await res.json();
    setWebUrlTypes(d.data.map((u: any) => AccountWebUrlType.fromApi(u)));
  };

  const parseUrls =  async (values: UrlPasteForm) => {
    const distinct = new Set(values.urls.split('\n'));
    const urls = { urls: Array.from(distinct) };
    if (urls.urls.length && !processing)  {
      setProcessing(true);
      const res = await fetch('/api/enforcement/url-parse', {
        method : 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(urls),
      });
      const d = await res.json();
      const checkedUrls: WebBotUrlField[] = d.data.map((u : any) => {

        return { url: u.url, client: u.client ? new AffinityClient(u.client) : null, type: u.type.id };
      });
      urlFieldArray.append(checkedUrls);
      setProcessing(false);
    }
  };

  const submitUrls = async (values: WebBotUrlForm) => {
    const urls = values.urls.map((u) => {
      let id = null;
      if (u.client) {
        id = u.client.id;
      }

      return { url: u.url, type: u.type, licensor: id };

    });
    const data = {
      urls,
      vendor: params['vendorId'],
    };
    const res = await fetch('/api/enforcement/urls', {
      method : 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    });
    history.push(`/vendors/${params['vendorId']}/enforce`);

  };

  const setLicensor = (values: {licensor: AffinityClient[]}) => {
    if (changeUrlLicensor !== null) {
      urlSubmissionForm.setValue(`urls.${changeUrlLicensor}.client`, values.licensor[0]);
      setChangeUrlLicensor(null);
    }

  };

  const typeOptions = webUrlTypes.map(t => (<option value={t.id}>{t.name}</option>));

  let panelBody;
  if (processing) {
    panelBody = <LoadingSpinner />;
  } else if (urlFieldArray.fields.length) {
    panelBody = (
      <form onSubmit={urlSubmissionForm.handleSubmit(submitUrls)}>
        <div>
          <p className="text-muted text-center">All clients will use their default trademark.</p>
          {urlSubmissionForm.formState.errors.urls && urlSubmissionForm.formState.errors.urls.root ? <div className="alert alert-danger">All urls must have a client.</div> : null
          }
          <div>
            {urlFieldArray.fields.map((u, i) => (
              <div key={u.id} className="web-bot-url-manual-row">
                <div className="manual-row-url">
                  <div>

                  <a href={urlSubmissionForm.getValues(`urls.${i}.url`)} target="_blank">{urlSubmissionForm.getValues(`urls.${i}.url`)}</a>
                  </div>
                </div>

                <div className="manual-row-type">
                  <select {... urlSubmissionForm.register(`urls.${i}.type`)} className="form-control">
                    {typeOptions}
                  </select>
                </div>

                <div onClick={() => setChangeUrlLicensor(i)} className="manual-row-client">
                  {urlSubmissionForm.getValues(`urls.${i}.client`) ?
                    <>
                      <img src={urlSubmissionForm.getValues(`urls.${i}.client`).affinityImage.getSize('th')} className="img-responsive" />
                      <strong>{urlSubmissionForm.getValues(`urls.${i}.client`).shortName}</strong>
                    </>
                    :
                    <strong>
                      Licensor not found - Click to set licensor
                    </strong>
                  }
                </div>
              </div>
            ))}
          </div>
        </div>
        <button type="submit" className="btn btn-primary  pull-right">Submit</button>
      </form>
    );

  } else {

    panelBody = (
      <form onSubmit={urlPasteForm.handleSubmit(parseUrls)}>
        <div className="form-group">
          <textarea rows={20} {...urlPasteForm.register('urls')} className="form-control">

          </textarea>
        </div>
        <button type="submit" className="btn btn-primary  pull-right">Next</button>
      </form>
    );
  }

  return (
    <FullContent>
      <h3> <strong>Add Enforcement Content</strong> </h3>
      <div className="panel panel-portal">
        <div className="panel-body">
          {panelBody}
        </div>
      </div>
      <GatewayModal
        type={ModalType.Primary}
        shown={changeUrlLicensor !== null}
        onClose={() => setChangeUrlLicensor(null)}
        title={'Change Licensor'}
      >
        <form onSubmit={changeLicensorForm.handleSubmit(setLicensor)}>
          <Modal.Body>
            <div>
              <div className="form-group">
                <label>Licensor</label>
                <LicensorTypeahead
                  selected={changeLicensorForm.watch('licensor')}
                  onChange={l => changeLicensorForm.setValue('licensor', l)}
                  multiple={false}
                />
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <button type="button" onClick={() => setChangeUrlLicensor(null)} className="btn btn-default">
              Close
            </button>
            <button type="submit" className="btn btn-primary pull-right">Change Licensor</button>
          </Modal.Footer>
        </form>
      </GatewayModal>
    </FullContent>
  );

};
